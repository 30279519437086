var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"registrationScreen",attrs:{"id":"registration-screen"}},[_c('header',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('pages.registration.title')))]),_c('h4',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('pages.registration.subtitle')))])]),_c('div',{staticClass:"content-row"},[_c('div',{staticClass:"container hungarospa-container"},[_c('div',{staticClass:"content-wrapper"},[_c('mobile-user-auth-tabs'),_c('h5',{staticClass:"form-title",class:{ 'sm-margin-bottom' : _vm.errorToast.isVisible }},[_vm._v(_vm._s(_vm.$t('pages.registration.form.title')))]),_c('error-toast',{attrs:{"isVisible":_vm.errorToast.isVisible,"text":_vm.errorToast.text}}),_c('section',{staticClass:"form-section"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.personalSection.sectionTitle)))]),_c('div',{staticClass:"inputs-wrapper"},_vm._l((_vm.personalSection.inputs),function(input,i){return _c('base-input',{key:input.key,attrs:{"labelText":_vm.$t(input.label),"hasError":input.hasError,"type":input.type ? input.type : 'text'},on:{"input":function($event){
var i$1 = arguments.length, argsArray = Array(i$1);
while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.handleInputs.apply(void 0, [ 'personal', i ].concat( argsArray ))}}})}),1)]),_c('section',{staticClass:"form-section"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.invoiceSection.sectionTitle)))]),_c('div',{staticClass:"checkboxes-wrapper"},_vm._l((_vm.invoiceSection.checkboxes),function(checkbox,i){return _c('base-checkbox',{key:i,attrs:{"label":_vm.$t(checkbox.label),"isChecked":checkbox.isChecked},on:{"changed":function($event){
var i$1 = arguments.length, argsArray = Array(i$1);
while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.handleCheckboxInput.apply(void 0, [ 'invoice', i ].concat( argsArray ))}}})}),1),_c('div',{staticClass:"inputs-wrapper"},[_vm._l((_vm.invoiceSection.inputs),function(input,i){return [(_vm.isCompanyNameInputVisible(i) && !input.options && _vm.isTaxNumberInputVisible(input.key))?_c('base-input',{key:input.key,attrs:{"labelText":_vm.$t(input.label),"hasError":input.hasError,"type":input.type ? input.type : 'text'},on:{"input":function($event){
var i$1 = arguments.length, argsArray = Array(i$1);
while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.handleInputs.apply(void 0, [ 'invoice', i ].concat( argsArray ))}}}):(input.options)?_c('base-selector',{key:input.key,attrs:{"options":input.options,"labelText":_vm.$t(input.label),"hasError":input.hasError},on:{"selected":_vm.handleCountrySelect}}):_vm._e()]})],2)]),_c('section',{staticClass:"form-section"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.passwordSection.sectionTitle)))]),_c('div',{staticClass:"inputs-wrapper"},_vm._l((_vm.passwordSection.inputs),function(input,i){return _c('base-input',{key:input.key,attrs:{"labelText":_vm.$t(input.label),"hasError":input.hasError,"type":input.type ? input.type : 'text'},on:{"input":function($event){
var i$1 = arguments.length, argsArray = Array(i$1);
while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.handleInputs.apply(void 0, [ 'password', i ].concat( argsArray ))}}})}),1)]),_c('section',{staticClass:"policies-section"},[_c('base-checkbox',{attrs:{"label":_vm.$t(_vm.policiesSection.label),"isChecked":_vm.policiesSection.isChecked,"hasError":_vm.policiesSection.hasError},on:{"changed":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleCheckboxInput.apply(void 0, [ 'policies', 0 ].concat( argsArray ))}}})],1),_c('section',{staticClass:"buttons-section"},[_c('base-button',{attrs:{"isLoading":_vm.isBtnLoading,"text":_vm.$t('pages.registration.buttons.registration')},on:{"clicked":_vm.handleRegistrationBtnClick}}),_c('span',{staticClass:"already-member-text"},[_vm._v(_vm._s(_vm.$t('pages.registration.alreadyMember')))]),_c('base-button',{attrs:{"isSecondary":true,"text":_vm.$t('pages.registration.buttons.login')},on:{"clicked":_vm.handleLoginBtnClick}})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }